// import { GetAd } from 'contexts/AdContext';
// import Image from "next/image";
// import Link from 'next/link';
// import { useEffect } from 'react';
// import { useState } from 'react';

import Styles from './FeaturedAd.module.scss';



function FeaturedAd({ ad }) {
    // const [ fallback, setFallback ] = useState(null)

    // useEffect(() => {
    //     if( !ad ){    
    //         const ads = GetAd("ads/search/typeahead", "search-typeahead-ad");
    
    //         if ( ads.isLoading ) { return null; }
    //         if ( ads.isError ) { return null; }
    
    //         setFallback(ads.data.entries[0])
    //     }

    // }, [ad])
    

    // console.log(fallback)

    return (
        <>
            {(ad && ad?.header) && (
                <div className={Styles.block}>
                    <div dangerouslySetInnerHTML={{ __html: ad?.header[0] }} />
                </div>
            )}

            {/* {!ad && fallback && (
                <Link href={fallback.adUrl}>
                    <a title={fallback.title}>
                        <Image 
                            alt={fallback.title}
                            src={fallback.image[0]?.srcset}
                            width={fallback.image[0]?.width}
                            height={fallback.image[0]?.height}
                        />
                    </a>
                </Link>
            )} */}
        </>
    );
}

export default FeaturedAd;