import { useState } from "react";
import { useQuery } from "react-query";
import SearchApi from 'services/plp/SearchApi.service';
import { SearchApiConstants } from "lib/Constants";
import { parseCookies } from 'nookies'



/** ------------------------------------
 *  Hook to handle the HeaderSearch logic
 --------------------------------------- */

export const useHeaderSearchState = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [newQuerySet, setNewQuerySet] = useState("");

    //AutoComplete API

    const fetchAutoComplete = ({ queryKey }) => {

        const cookies = parseCookies();
        const userToken = cookies?.TEAK_searchUserToken ?? null;

        return SearchApi.get(SearchApiConstants.END_POINTS.AUTO, { params: { q: queryKey[1], userToken } })
    }

    const useAutoComplete = (q, isInFocus = true) => {
        return useQuery(
            ['typeahead', q],
            fetchAutoComplete,
            {
                cacheTime: 0,
                select: (data) => {   //data transform for the search results

                    // const merchandising = data?.data?.merchandising?.content?.header
                    const merchandising = data?.data

                    return (merchandising !== undefined || merchandising !== "") ? merchandising : null
                },
                enabled: isInFocus && q.length > 0
            }
        );
    };

    return {
        searchQuery,
        newQuerySet,
        setSearchQuery,
        setNewQuerySet,
        useAutoComplete
    };
};