import Style from './RecommendedContent.module.scss';
import useDebounce from 'react-use/lib/useDebounce';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import envUrl from 'lib/Utils/envUrl';

function RecommendedContent({ products = [], searchQuery, isTrade }) {
    const [ query, setQuery ] = useState("")
    const [ items, setItems ] = useState([])
    const [ _, cancel ] = useDebounce(() => {
        setQuery(searchQuery)

    }, 500, [searchQuery])


    return (
        <>
           {products.length || items.length ? (
                <ul className={Style.row}>
                    {products?.length ? 
                        <>
                            {products.map((item, index) => {
                                if( index > 1){ return null }

                                return(
                                    <Item 
                                        key={`item_${index}`}
                                        {...item}
                                    />
                                )
                            })}

                        </>
                    :
                        <>
                            {items.map((item, i) => {
                                return(
                                    <Item 
                                        key={`item_${i}`} 
                                        {...item} 
                                    />
                                )
                            })}
                        </>
                    }
                </ul>
           ):null}
        </>

    );
}




function Item({ 
    title, 
    url, 
    source, 
    highlightedResults,
    category,
    ...other 
}){
    const newUrl = envUrl(url)
    const [ heading, setHeading ] = useState("")

    useEffect(() => {
        switch(source){
            case "cms_pages": setHeading("articles"); break;
            case "blog_post": setHeading("blogs"); break;
            case "zendesk_article": setHeading("help articles"); break;

            default: setHeading(source)
        }

    }, [ source ])




    return (
        <li className={Style.item}>
            <span className={Style.heading}>
                {heading.split("_").join(" ")}
            </span>

            <Link href={`${newUrl}`} title={title} className={Style.link}>
                <p 
                    className={Style.title} 
                    dangerouslySetInnerHTML={{__html: title}} 
                />

                <p 
                    className={Style.text} 
                    dangerouslySetInnerHTML={{ __html: highlightedResults }} 
                />
            </Link>
        </li>
    );
}





export default RecommendedContent;