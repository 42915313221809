

export function stringToSlug(str, separator = "_") {
    str = str.trim();
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    return str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);
}




//calculate the discount percentage on price
export function discountedPercentage(oPrice, dPrice) {
    return Math.round(100 * (oPrice - dPrice) / oPrice)
}





// take string as input and build array with keys and values
export function buildParamsObj(q, callbackFunc) {
    const str = (q.indexOf('?') > -1) ? decodeURIComponent(q.split('?')[1]?.trim()) : q;

    // parse query string
    const params = new URLSearchParams(str);
    const obj = {};


    // iterate over all keys and build array with values for repeated keys
    for (const key of params.keys()) {
        if (params.get(key) !== '') {
            (params.getAll(key).length > 1) ? 
                obj[key] = params.getAll(key) 
                : 
                obj[key] = params.get(key);
        }
    }


    const newObj = {};

    // clean up keys if the key was legacy and encoded
    Object.keys(obj).forEach(key => {
        const newKey = key.includes("/") ? key.split("/")[1] : key;

        newObj[newKey] = obj[key]
    })


    //if the object has 'slug' key remove it - category landing page doesn't need it as pmt for SSAPI
    if (newObj.hasOwnProperty('slug')) {
        delete newObj['slug'];
    }


    // remove the legacy search spring $2520 and add a space
    Object.keys(newObj).forEach(key => {
        newObj[key] = ( typeof newObj[key] === "string" ) ? newObj[key].replace(/\$2520/g, " ") : newObj[key]
    })

    return ( callbackFunc ) ? callbackFunc(newObj) : newObj;
}






// take object and split the price filter to low & high
export function rewritePriceForAPIReq(obj) {
    const filters = ["filter.calculated_price"];

    filters.map(ind => {
        if (obj && obj.hasOwnProperty(ind)) {
            const val = obj[ind];
            const lowPrice = []
            const highPrice = []

            // if the value is Array
            if (Array.isArray(val)) {
                val.map(v => {
                    const price = v.split(":")
                    const low = price[0]?.trim()
                    const high = price[1]?.trim()
                    lowPrice.push(low)
                    highPrice.push(high)
                });

                obj['filter.calculated_price.low'] = lowPrice;
                obj['filter.calculated_price.high'] = highPrice;
            
            }else { 
                //single value
                const v = val.split(':');

                obj['filter.calculated_price.low'] = v[0]?.trim();
                obj['filter.calculated_price.high'] = v[1]?.trim();
            }

            delete obj[ind];
        }

    });
    return obj;
}





// take checkbox/price range filter object and build tags 
export function getTagNames(
  checkedFilters,
  facets
) {
  let tagArr = [];

//   tagState.length &&
//     tagState.map((index) => {
//       if (index.includes('$')) {
//         // check for price  tag
//         tagArr.push(index);
//       }
//     });

  let checkedStateArr = buildParamsObj(checkedFilters.join('&'));

  Object.keys(checkedStateArr).forEach((e) => {
    const getObj = facets.filter((item) => {
      return item.field === e.split('filter.')[1]?.trim();
    });

    const tagLabel = getObj[0]?.label;

    // if the value is Array
    if (Array.isArray(checkedStateArr[e])) {
      checkedStateArr[e].map((v) => {
        tagArr.push(tagLabel + ': ' + v);
      });
    } else {
      tagArr.push(tagLabel + ': ' + checkedStateArr[e]);
    }
  });

  return tagArr;
}





//take obj built from url and get each checked/sort/page/priceRange objects separately
export function getPLPStateFromURL(obj) {
    let checkedStateFromURL = [];
    let sortStateFromURL = "";
    let inputStateFromUrl = {};
    let pageStateFromURL = 1;
    let priceRangeFromURL = "";
    let minPriceRange = 0;
    let maxPriceRange = 0;
    let perPageFromURL = 24;


    Object.keys(obj).forEach(e => {
        if ( e.startsWith('filter.') ) {


            if ( Array.isArray(obj[e]) ) {
                const arr = obj[e].map(el => e + '=' + el);
                checkedStateFromURL = checkedStateFromURL.concat.apply(checkedStateFromURL, arr);

            }else if( (e.toLowerCase()).endsWith('map_price') ) {    
                priceRangeFromURL = (e + '=' + obj[e]),
                minPriceRange = parseInt(obj[e].split(':')[0]),
                maxPriceRange = (obj[e].split(':')[1] === '*') ? obj[e].split(':')[1] : parseInt(obj[e].split(':')[1])
             
            }else if( e.includes(".low") || e.includes(".high") ){
                inputStateFromUrl = {
                    ...inputStateFromUrl,
                    [e]: obj[e]
                }

            }else{
                checkedStateFromURL.push(e + '=' + obj[e]);
            }
        }
        

        if (e.startsWith('sort.')) {
            sortStateFromURL = e + '=' + obj[e]
        }


        if (e.startsWith('page')) {
            pageStateFromURL = parseInt(obj[e]);
        }


        if( e.startsWith('resultsPerPage')){
            perPageFromURL = parseInt(obj[e])
        }

    })


    // return as an array
    return [ 
        checkedStateFromURL, 
        sortStateFromURL, 
        inputStateFromUrl,
        pageStateFromURL, 
        perPageFromURL, 
        priceRangeFromURL, 
        minPriceRange, 
        maxPriceRange
    ];
}




//build params obj to be sent to CSR as input based on filtering
export function buildURLParams({ 
    router, 
    catHierarchy, 
    priceRange, 
    checkedFilters, 
    page, 
    sortBy, 
    inputFilter,
    lastPage, 
    perPage, 
    noneChecked, 
    firstRun 
}) {

    let paramObj = {}

    if ( router.query.q ) {
        paramObj = { 
            q: router.query.q 
        }
    }
    
    if ( router.query.slug ) {
        paramObj['bgfilter.categories_hierarchy'] = catHierarchy;
    }
    

    let obj = {};

    if( checkedFilters.length ){
        obj = buildParamsObj(checkedFilters.join('&'))

    }else if( noneChecked && !firstRun ){
        obj = buildParamsObj("")

    }else{
        obj = buildParamsObj(router.asPath)
    }


    paramObj = { 
        ...paramObj, 
        ...obj, 
        ...inputFilter,
        ...sortBy, 
        ...priceRange, 
        resultsPerPage: perPage, 
       // page: obj?.page ?? page
        page:page
    };


    // debugger


    //if previous page exists or page is in the query param
    // if ((page && lastPage && lastPage !== page) || (router.query.page)) {
    //     paramObj = { ...paramObj, page };
    // }
  

    return paramObj;
}




//shallow route the page with filters
export function ShallowRouteParams(router, paramObj) {

    //default set to search page
    let pathName = '/search';
    let routeParams = paramObj;

    //if router.query has slugs
    if ( router.query.slug ){
        pathName = router.pathname.split('[...slug]')[0]
        pathName += router.query.slug.join('/')
    }


    //not  for the shallow route
    if ( routeParams.hasOwnProperty('bgfilter.categories_hierarchy') ){
        delete routeParams["bgfilter.categories_hierarchy"];
    }


    return [ pathName, routeParams ];
}