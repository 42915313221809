
export default function getFileName(image, fallback){
    const filename = image ? image : fallback; 

    if( filename ){
        const fileIndex = filename.lastIndexOf("/") + 1;
        return filename.substr(fileIndex);
        
    }else{
        return "dummy-image-400x300.webp";
    }
}