import React from 'react';
import Link from 'next/link';
import { useHSContext } from 'components/applications/header_footer/Header/contexts/HeaderSearchContext';
import Loader from 'components/feedback/Loader';

import Styles from './ResultsList.module.scss';
import utils from 'styles/globals/utils.module.scss';


function ResultsList({ searchList, noList, loadingList }) {
    //use Header search context
    const { setNewQuerySet } = useHSContext();

    const handleHover = (e) => {
        setNewQuerySet(e.target.title)
    }


    if (loadingList) { 
        return (
            <div className={utils.py_4}>
                <Loader isLoading />
            </div>
        ) 
    }

    if (noList) { return null; }

    return <>
        {
            (searchList !== null) && (
                <ul className={Styles.list}>
                    {searchList && searchList.map(val => {
                        return (
                            <li key={val} className={Styles.item}>
                                <Link
                                    href={{
                                            pathname: "/search",
                                            query:{ q: val }
                                        }}
                                    title={val}
                                    onMouseOver={handleHover}
                                    className={Styles.link}
                                >
                                    {val}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )
        }
    </>;
}



export default ResultsList;
