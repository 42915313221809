import { createContext, useContext } from 'react';


/** --------------------------
 *  Header Search Context
 ---------------------------- */
export const HSContext = createContext({});

// Export Provider
export const HSProvider = (props) => {
    const { value, children } = props;
    const HSState = { ...value.HSFilters }

    return (
        <HSContext.Provider value={HSState}>
            {children}
        </HSContext.Provider>
    );
};


// Export useContext Hook.
export function useHSContext() {
    const HSState = useContext(HSContext);
    return HSState;
}

