import clsx from 'clsx';
import Link from "next/link"
import Style from './ViewMore.module.scss';
import { IconChevronRight } from 'components/icons/IconChevronRight';


function ViewMore({ searchQuery, totalResults = "", label, showLink=true }) {
    return(
        <>
            {totalResults > 0 && (
                <p className={clsx(Style.block)}>
                    <strong className={Style.title}>
                        About {totalResults.toLocaleString()} {!label ? "product" : label} result{totalResults > 1 && "s"} for: <em>{searchQuery}</em>
                    </strong>

                    {showLink && (
                        <Link
                            href={{
                                pathname: "/search",
                                query: { q: searchQuery }
                            }}
                            title={`View all`}
                            className={clsx(Style.link)}
                        >    
                            View all results <IconChevronRight width="15" height="15" />
                        </Link>
                    )}
                </p>  
            )}
        </>
    )
}

export default ViewMore;