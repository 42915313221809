/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { HSProvider } from 'components/applications/header_footer/Header/contexts/HeaderSearchContext';
import { useHeaderSearchState } from 'custom_hooks/useHeaderSearch';
import Results from "./Results";



export default function Typeahead({ query, isInFocus, isTrade }) {
    const [contextData, setContextData] = useState({ query } || {});
    const HSFilters = useHeaderSearchState(contextData);


    useEffect(() => {        
        if ( query !== "") {
            setContextData({ query })
            HSFilters.setSearchQuery(query)
        }

    }, [ query ]);

    return (
        <HSProvider value={{ HSFilters }}>
            <Results {...{ isInFocus, isTrade, query }} />
        </HSProvider>
    )
}
