import { product } from "../fragments/product";


// fetches recommendations based on search terms
export default function GetSearchRecommendations(query){
    return `
        query {
            recos (preview: false, image: VERSION_7_200_200) {
                q_related: search(term: "${query}", params: {
                    minProducts: 1
                    maxProducts: 4
                }) {
                    primary {
                        ...product
                    }
                }
            }
        }

        ${product}
    `
}